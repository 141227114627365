import { InMemoryCache } from "apollo-cache-inmemory";
// import { ApolloClient } from "apollo-client";
import { ApolloLink, concat, Operation, split } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";
import { ApolloClient } from "apollo-client";

// const getToken = () => {
//   const idToken = window.localStorage.getItem("id_token");
//   return idToken ? `Bearer ${idToken}` : ``;
// };

const cache = new InMemoryCache();

// const authMiddleware = new ApolloLink((operation: Operation, forward: any) => {
//   operation.setContext({
//     headers: {
//       authorization: getToken()
//     }
//   });
//   return forward(operation);
// });
// console.log(process.env.REACT_APP_GRAPHQL_URI);
const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URI });

// const combinedLinks = split(({ query }: any) => {
//   const { kind, operation }: any = getMainDefinition(query);
//   return kind === "OperationDefinition" && operation === "subscription";
// }, httpLink);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      console.error(`graphQLError: ${error.message}`);
    });
  }
  if (networkError) {
    console.error(`Network error: ${networkError}`);
  }
});

// export const client = new ApolloClient({
//   cache,
//   link: ApolloLink.from([errorLink, concat(authMiddleware, combinedLinks)]),
//   connectToDevTools: process.env.NODE_ENV === "development"
// });

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache
});
