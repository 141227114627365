import * as React from "react";

class Home extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className="home-banner">
        </div>

        <div className="article-wrapper">
          <h1>Galaxy Conquest</h1>
          <p>
            Galaxy Conquest is a cyberpunk living card game (LCG) where you
            battle other Fleet Admirals for control over the Galaxy!
          </p>
          <h2>Welcome to the future</h2>
          <h3>What Galaxy Conquest is about</h3>
          <p>
            You live in an era where space travel is a reality. You are a Fleet
            Admiral and have been chosen by the leaders of your people to go
            conquer the Galaxy. Your species is not the only one to seek control
            over the galaxy, so you must battle other Fleet Admiral in order to
            secure your people's empire. You have free reins! Make your own
            decisions: choose your crew, your spaceships, your allies.
            Everything is in your hands, can you make the best of it? Where, how
            and with who do I play?
          </p>
          <p>
            As of where, you can play anywhere; at home, at the coffee shop, at
            a local game store, but the most exciting is when there is a Galaxy
            Conquest Tournament! There you can meet and compete with other
            players and win prizes! Events like these are always posted on
            facebook. As of how to play, a friend who already knows how to play
            can teach you, and reading this website will help you figure out how
            to deal with more complicated situations. And of course, you can
            always post your question on the page and I will look at it and help
            you out.
          </p>
          <h2>What's New</h2>
          <p>Tons of new playmats, and more to come!</p>
          <p>
            Gamma set has made its way to through the printer and is available
            for purchase, along with a few fixes for Alpha and Beta!
          </p>
          <p>Check out the new deckbuilder!</p>
          <p>
            Play Galaxy Conquest on Cockatrice! You don't want to buy cards? You
            would like to help test out the new set? Contact me via Facebook to gain access to the card database.
          </p>
          <p>This site is under construction.</p>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
