import gql from "graphql-tag";
import * as React from "react";
import * as ReactApollo from "react-apollo";
import * as ReactApolloHooks from "react-apollo-hooks";

export type Maybe<T> = T | null;

/** ordering options when selecting data from "glossary" */
export interface GlossaryOrderBy {
  definition?: Maybe<OrderBy>;

  id?: Maybe<OrderBy>;

  word?: Maybe<OrderBy>;
}
/** Boolean expression to filter rows from the table "glossary". All fields are combined with a logical 'AND'. */
export interface GlossaryBoolExp {
  _and?: Maybe<(Maybe<GlossaryBoolExp>)[]>;

  _not?: Maybe<GlossaryBoolExp>;

  _or?: Maybe<(Maybe<GlossaryBoolExp>)[]>;

  definition?: Maybe<StringComparisonExp>;

  id?: Maybe<IntComparisonExp>;

  word?: Maybe<StringComparisonExp>;
}
/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export interface StringComparisonExp {
  _eq?: Maybe<string>;

  _gt?: Maybe<string>;

  _gte?: Maybe<string>;

  _ilike?: Maybe<string>;

  _in?: Maybe<string[]>;

  _is_null?: Maybe<boolean>;

  _like?: Maybe<string>;

  _lt?: Maybe<string>;

  _lte?: Maybe<string>;

  _neq?: Maybe<string>;

  _nilike?: Maybe<string>;

  _nin?: Maybe<string[]>;

  _nlike?: Maybe<string>;

  _nsimilar?: Maybe<string>;

  _similar?: Maybe<string>;
}
/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export interface IntComparisonExp {
  _eq?: Maybe<number>;

  _gt?: Maybe<number>;

  _gte?: Maybe<number>;

  _in?: Maybe<number[]>;

  _is_null?: Maybe<boolean>;

  _lt?: Maybe<number>;

  _lte?: Maybe<number>;

  _neq?: Maybe<number>;

  _nin?: Maybe<number[]>;
}
/** order by aggregate values of table "glossary" */
export interface GlossaryAggregateOrderBy {
  avg?: Maybe<GlossaryAvgOrderBy>;

  count?: Maybe<OrderBy>;

  max?: Maybe<GlossaryMaxOrderBy>;

  min?: Maybe<GlossaryMinOrderBy>;

  stddev?: Maybe<GlossaryStddevOrderBy>;

  stddev_pop?: Maybe<GlossaryStddevPopOrderBy>;

  stddev_samp?: Maybe<GlossaryStddevSampOrderBy>;

  sum?: Maybe<GlossarySumOrderBy>;

  var_pop?: Maybe<GlossaryVarPopOrderBy>;

  var_samp?: Maybe<GlossaryVarSampOrderBy>;

  variance?: Maybe<GlossaryVarianceOrderBy>;
}
/** order by avg() on columns of table "glossary" */
export interface GlossaryAvgOrderBy {
  id?: Maybe<OrderBy>;
}
/** order by max() on columns of table "glossary" */
export interface GlossaryMaxOrderBy {
  definition?: Maybe<OrderBy>;

  id?: Maybe<OrderBy>;

  word?: Maybe<OrderBy>;
}
/** order by min() on columns of table "glossary" */
export interface GlossaryMinOrderBy {
  definition?: Maybe<OrderBy>;

  id?: Maybe<OrderBy>;

  word?: Maybe<OrderBy>;
}
/** order by stddev() on columns of table "glossary" */
export interface GlossaryStddevOrderBy {
  id?: Maybe<OrderBy>;
}
/** order by stddev_pop() on columns of table "glossary" */
export interface GlossaryStddevPopOrderBy {
  id?: Maybe<OrderBy>;
}
/** order by stddev_samp() on columns of table "glossary" */
export interface GlossaryStddevSampOrderBy {
  id?: Maybe<OrderBy>;
}
/** order by sum() on columns of table "glossary" */
export interface GlossarySumOrderBy {
  id?: Maybe<OrderBy>;
}
/** order by var_pop() on columns of table "glossary" */
export interface GlossaryVarPopOrderBy {
  id?: Maybe<OrderBy>;
}
/** order by var_samp() on columns of table "glossary" */
export interface GlossaryVarSampOrderBy {
  id?: Maybe<OrderBy>;
}
/** order by variance() on columns of table "glossary" */
export interface GlossaryVarianceOrderBy {
  id?: Maybe<OrderBy>;
}
/** select columns of table "glossary" */
export enum GlossarySelectColumn {
  Definition = "definition",
  Id = "id",
  Word = "word"
}
/** column ordering options */
export enum OrderBy {
  Asc = "asc",
  AscNullsFirst = "asc_nulls_first",
  AscNullsLast = "asc_nulls_last",
  Desc = "desc",
  DescNullsFirst = "desc_nulls_first",
  DescNullsLast = "desc_nulls_last"
}

// ====================================================
// Documents
// ====================================================

export type FetchGlossaryVariables = {};

export type FetchGlossaryQuery = {
  __typename?: "Query";

  glossary: FetchGlossaryGlossary[];
};

export type FetchGlossaryGlossary = {
  __typename?: "glossary";

  definition: string;

  id: number;

  word: string;
};

// ====================================================
// Components
// ====================================================

export const FetchGlossaryDocument = gql`
  query fetchGlossary {
    glossary {
      definition
      id
      word
    }
  }
`;
export class FetchGlossaryComponent extends React.Component<
  Partial<ReactApollo.QueryProps<FetchGlossaryQuery, FetchGlossaryVariables>>
> {
  render() {
    return (
      <ReactApollo.Query<FetchGlossaryQuery, FetchGlossaryVariables>
        query={FetchGlossaryDocument}
        {...((this as any)["props"] as any)}
      />
    );
  }
}
export type FetchGlossaryProps<TChildProps = any> = Partial<
  ReactApollo.DataProps<FetchGlossaryQuery, FetchGlossaryVariables>
> &
  TChildProps;
export function FetchGlossaryHOC<TProps, TChildProps = any>(
  operationOptions:
    | ReactApollo.OperationOption<
        TProps,
        FetchGlossaryQuery,
        FetchGlossaryVariables,
        FetchGlossaryProps<TChildProps>
      >
    | undefined
) {
  return ReactApollo.graphql<
    TProps,
    FetchGlossaryQuery,
    FetchGlossaryVariables,
    FetchGlossaryProps<TChildProps>
  >(FetchGlossaryDocument, operationOptions);
}
export function useFetchGlossary(
  baseOptions?: ReactApolloHooks.QueryHookOptions<FetchGlossaryVariables>
) {
  return ReactApolloHooks.useQuery<FetchGlossaryQuery, FetchGlossaryVariables>(
    FetchGlossaryDocument,
    baseOptions
  );
}
