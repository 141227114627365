import * as React from "react";
import {
  FetchGlossaryComponent,
  FetchGlossaryGlossary
} from "../../generated/graphql";
import { Suggest, ItemRenderer } from "@blueprintjs/select";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import { MenuItem, Classes, Spinner, Icon } from "@blueprintjs/core";
import { compose } from "react-apollo";
import { withRouter } from "react-router";
import { History } from "history";
import qs from "qs";
import { Link } from "react-router-dom";

interface IProps {
  history: History;
}
interface IRefMap {
  [key: string]: React.RefObject<HTMLDivElement>;
}

interface IState {
  refMap: IRefMap;
  initialLoadComplete: boolean;
}

class Glossary extends React.PureComponent<IProps, IState> {
  state: IState = {
    refMap: {},
    initialLoadComplete: false
  };
  handleItemSelect = (item: FetchGlossaryGlossary): void => {
    const { history } = this.props;
    history.push({ hash: item.word });
    const ref = this.state.refMap[`${item.word}${item.id}`];
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    }
  };

  suggestionRenderer = (
    word: FetchGlossaryGlossary,
    { modifiers, handleClick }: any
  ) => {
    return (
      <MenuItem
        key={word.id}
        className={modifiers.ACTIVE ? Classes.ACTIVE : ""}
        text={`${word.word}`}
        onClick={handleClick}
      ></MenuItem>
    );
  };
  setRefs = (refMap: IRefMap) => {
    if (!this.state.initialLoadComplete) {
      this.setState({
        refMap,
        initialLoadComplete: true
      });
    }
  };

  render() {
    const { history } = this.props;
    const currentHash = history.location.hash;
    console.log(currentHash);
    return (
      <FetchGlossaryComponent>
        {({ error, loading, data }) => {
          console.dir(error);
          if (loading) {
            return <Spinner />;
          }
          const refMap =
            data &&
            data.glossary &&
            data.glossary.reduce((prev, curr) => {
              const ref: React.RefObject<HTMLDivElement> = React.createRef();
              return {
                ...prev,
                [`${curr.word}${curr.id}`]: ref
              };
            }, {});
          if (refMap) {
            this.setRefs(refMap);
          }
          return (
            <div className="glossary bp3-running-text article-wrapper">
              <div className="bp3-dark">
                <Suggest
                  onItemSelect={this.handleItemSelect}
                  items={data && data.glossary}
                  resetOnSelect={true}
                  inputValueRenderer={item => {
                    return item.word;
                  }}
                  itemRenderer={this.suggestionRenderer}
                ></Suggest>
              </div>
              {data &&
                data.glossary &&
                data.glossary.map(word => {
                  return (
                    <div
                      className={`bp3-callout word-card ${
                        currentHash === `#${word.word}`
                          ? "bp3-intent-primary"
                          : ""
                      }`}
                      key={word.id}
                      ref={this.state.refMap[`${word.word}${word.id}`]}
                    >
                      <div className="row">
                        <h4 className="bp3-heading">{word.word}</h4>
                        <Icon
                          onClick={() => this.handleItemSelect(word)}
                          className="word-link"
                          icon="link"
                        ></Icon>
                      </div>
                      <p>{word.definition}</p>
                    </div>
                  );
                })}
            </div>
          );
        }}
      </FetchGlossaryComponent>
    );
  }
}

export default compose(withRouter)(Glossary);
