import * as React from "react";
import { Navbar, Alignment, Button } from "@blueprintjs/core";
import { NavLink, withRouter } from "react-router-dom";
import { History } from "history";
import { compose } from "react-apollo";

interface IProps {
  history: History;
}

class Navigation extends React.PureComponent<IProps> {
  render() {
    const { history } = this.props;
    return (
      <div className="nav-wrapper">
        <Navbar className="bp3-dark" fixedToTop={true}>
          <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>Galaxy Conquest</Navbar.Heading>
            <Navbar.Divider />
            <NavLink
              isActive={() => history.location.pathname === "/"}
              className="bp3-button bp3-minimal"
              activeClassName="bp3-active"
              to="/"
            >
              Home
            </NavLink>
            <NavLink
              className="bp3-button bp3-minimal"
              activeClassName="bp3-active"
              to="/how-to-play"
            >
              How to Play
            </NavLink>
            <NavLink
              className="bp3-button bp3-minimal"
              activeClassName="bp3-active"
              to="/glossary"
            >
              Glossary
            </NavLink>
            <NavLink
              className="bp3-button bp3-minimal"
              activeClassName="bp3-active"
              to="/tips"
            >
              Tips
            </NavLink>
            <NavLink
              className="bp3-button bp3-minimal"
              activeClassName="bp3-active"
              to="/card-database"
            >
              Card Database
            </NavLink>
          </Navbar.Group>
        </Navbar>
      </div>
    );
  }
}

export default compose(withRouter)(Navigation);
