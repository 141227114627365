import * as React from "react";

class Home extends React.PureComponent {
  render() {
    return (
      <div className="article-wrapper">
        <h2>General guidelines</h2>
        <ul>
          <li>
            Everything has a rule for it. If you don't understand how something
            works, no problem! Write a message on Facebook or send us an email.
          </li>
          <li>
            Do not expect to understand everything in a short time. Galaxy
            Conquest is a very complex game with simple basics. Start by
            learning the basics, then move onto something more complicated.
          </li>
          <li>
            Play with someone better than you. This way you can learn and have
            fun (if you don't mind getting a beating).
          </li>
          <li>
            Unless specified otherwise, a selection only targets a permanent.
          </li>
          <li>
            Everything is always inclusive. For example, a BR creature is both
            black and red. A tech creature is both a tech and a creature.
          </li>
          <li>
            Store your cards in a binder inside transparent collectible cards
            sheets! If you file them using the card number, they will be ordered
            by color and name, making it very easy to find cards you are looking
            for and find cards that suit your strategy.
          </li>
        </ul>
        <h2>Building Decks</h2>
        <ul>
          <li>
            Building a deck is the best part of a (drum roll) deck building
            game! Though it can be hard for a beginner. Start with
            preconstructed decks that are available on the website. Play with
            them and change cards for some that you prefer. Look at other
            people's list and use them to make your own.
          </li>
          <li>
            Once you're comfortable swapping cards in a deck, try one from
            scratch. All cards are made to fit together. Try to find patterns
            and assemble cards that seem to work well with one another. You
            might even find some combo pieces!
          </li>
          <li>
            A deck has a minimum of 60 cards but no maximum. That being said, it
            is rarely good to go over 60 cards because you are diluting your
            cards with others that might not fit as well.
          </li>
          <li>
            A deck with only one wincon (win condition) is a deck that can be
            powerless against only 1 good sideboard card.
          </li>
          <li>
            Your deck is only as good as your sideboard. Know the cards, know
            what your friends play, know the weaknesses of your deck, and know
            which card to swap for which.
          </li>
        </ul>
        ​<h2>What to bring to a game day</h2>
        <ul>
          <li>
            Your deck and sideboard. It is STRONGLY recommended to sleeve your
            deck and store it in a Deckbox.
          </li>
          <li>A way to track life, 20 sided dice, for example.</li>
          <li>Pen and paper to take notes during matches.</li>
          <li>
            A Playmat. Not all player use them, but they make the experience
            that much more enjoyable.
          </li>
        </ul>
        <p>
          {" "}
          All these items are available at any game store nearby you. These are
          suggestions, only the deck and a way to track life are mandatory.{" "}
        </p>
        <h2>Tips</h2>
        <ul>
          <li>
            The first thing you should learn is every step of a turn and how the
            stack works. This way you'll make the best use of every cards you
            have in hand. To do this, use the Turn Structure card found behind
            every token.
          </li>
          <li>
            ​You have a hack in hand? It is usually more beneficial to play it
            during your opponent's turn to surprise him. Even if your card has
            no direct effect on your opponent, you can save your energy for the
            turn for something unexpected or even just bluff a counter
            selection! Generally, the later you can make a selection, the
            better. Let's take an example. You have "Research" in hand. Research
            is a hack that reads: "Datamine 2, then draw​​​ two cards." You
            could do it during your turn, but it costs 3 energy and your
            opponent will understand that you are short on interactive
            selections and on energy because your planets are already tapped,
            creating an open window for him to play anything he wants without
            risk. Now let's say you pass your turn without playing anything,
            leaving all our energy up. Your opponent will think you are holding
            an interactive selection and might not be willing to take the risk
            to play his big card because you might kill it or counter it. At his
            end step, before your turn begins, would be the perfect moment to
            select Research.
          </li>
        </ul>
        <p>
          Want more tips? Got some for other players? Visit our Facebook page!
        </p>
        <a href="https://www.facebook.com/GalaxyConquestLCG/" target="_blank">
          <span>Facebook Page</span>
        </a>
      </div>
    );
  }
}

export default Home;
