import * as React from "react";

class HowToPlay extends React.PureComponent {
  render() {
    return (
      <div className="article-wrapper">
        <h2>Overview</h2>

        <p>
          In the Galaxy Conquest game, you play the role of a Fleet Admiral—a
          powerful general who fights other Fleet Admirals for control over the
          Galaxy. Your database of cards represents all the weapons in your
          arsenal. It contains all the selections you can make, all the tricks
          you know and all the creatures you can materialize on a planet's
          surface.
        </p>

        <p>
          Galaxy Conquest is a deck building game. You design and build your own
          unique deck, and each game you play is different. The best part about
          a Living Card Game is that you can pay only for the cards you want, or
          buy the whole set if you feel like it! There is no rarity, no
          randomization of cards. We will continue to release new cards and sets
          to keep the content changing and to expand Galaxy Conquest's universe.
          The purpose of this game is not to make money (since we will get sued
          if we do), but to share a wonderful game you can enjoy with your
          friends and your family. That being said, everything is sold at cost
          price. We hope you will enjoy and play the game over and over!
        </p>

        <h2>Colors</h2>

        <p>
          Only one thing is needed to power all your selections: energy. The
          five colors of energy are harvested from planets you control. Each
          color of energy fuels a different kind of selections. For example,
          white cards have a white symbol just like the one below in their
          costs, and tapping (turning) a Desert gives you white energy that you
          can spend to make white selections. It’s up to you whether to use one
          color or all five.
        </p>

        <h2>White</h2>

        <p>
          White energy comes from Deserts found on sdesertic planets. White is
          the color of those who believe in honor, numbers and technology. They
          are relying heavily on technology to give them strength and
          adaptability. Their large army of droids are feared throughout the
          Galaxy.
        </p>

        <h2>Blue</h2>

        <p>
          Blue energy comes from the large Seas found on watery planets. ​Blue
          is the color of those who believe in knowledge, wisdom and research.
          They think -with reason- that knowledge is power. They are willing to
          bend the rules of nature to achieve their goals. They invest most of
          their resources into R&D and technology.​
        </p>

        <h2>Green</h2>

        <p>
          Green energy comes from deep Forests found on planets full of life.
          ​Green is the color of those who are one with nature. They believe
          that nature is all powerful and are developing technology that
          respects and harnesses nature. They are also natural predators who
          don't need too much technology to deal lots of damage.
        </p>

        <h2>Black</h2>

        <p>
          Black energy comes from Caverns found on uninhabited planets. Black is
          the color of those who are renegade and cast out form their peers.
          They are the twisted ones, those who will find any ways to achieve
          their goals. Creative, ready to sacrifice any and all, imaginative,
          their power resides in their willingness to pay a price for it.
        </p>

        <h2>Red</h2>
        <p>
          Red energy comes from Lava found on scorched planets. Red is the color
          of Insects and other disgusting creatures. They are not smart but they
          are many - and fast. They are a community with no sense of
          individualism. They will gladly sacrifice their life for the greater
          good of their people and that's where their power lies.
        </p>

        <h2>Parts of a card</h2>

        <ul>
          <li>Card name</li>

          <li>
            Energy cost. Energy is the main resource in the game. It’s produced
            by planets, and you spend it to make selections. The symbols in a
            card’s left side tell you the cost to select this card. If the
            energy cost reads 1U, than you must pay 1 energy of any kind and 1
            blue energy to select it.
          </li>

          <li>
            Type line. This line contains the type (Artifact, Creature, Fleet
            Admiral, Hack, Planet, Shipment or Tech), subtype and supertype (if
            any). For example, Data Expert is a creature and its subtype is
            Stulom.
          </li>

          <li>
            Set symbol. This symbol tells which set is this card part of. For
            example, this symbol is the Alpha set.
          </li>

          <li>
            Text box. This is where a card’s abilities appear. You may also find
            flavor text printed in italics (<em> like this</em> ) that tells you
            something about Galaxy Conquest's world. Flavor text has no effect
            on game play. Some abilities have italic reminder text to help
            explain what they do.
          </li>

          <li>
            Card number. The collector number makes it easier to organize your
            cards. For example, “102/389” means that the card is the 102nd of
            389 cards in its set.
          </li>

          <li>
            Power and Toughness. Each creature card has a special box with its
            power and toughness. A creature’s power (the first number) is how
            much damage it deals in combat. Its toughness (the second number) is
            how much damage must be dealt to it in a single turn to destroy it.
            (A Fleet Admiral card has a different special box with its loyalty
            here.)
          </li>
        </ul>

        <h2>Card types</h2>

        <h2>Shipment</h2>

        <p>
          You can select a shipment only during a main phase of one of your own
          turns. You can’t select it when another selection is on the stack.
          (You’ll learn about phases and the stack in a bit.) A shipment has its
          effect (in other words, you follow the instructions on the card), then
          you put it into your graveyard, which is the game term for your
          discard pile.
        </p>

        <h2>Hack</h2>

        <p>
          A hack is just like a shipment, except you can select it just about
          any time you want, even during your opponent’s turn or in response to
          another selection. Like a shipment, a hack has its effect, then you
          put it into your graveyard.
        </p>

        <h2>Tech</h2>

        <p>
          A tech is a permanent . This means two things: You can select one only
          at the time you could select a shipment, and after you select one,
          you’ll put it on the table in front of you, near your planets. (Most
          players keep their planets closer to them, then put their other cards
          closer to the middle of the table.) The card is now on the
          battleground . Any of your cards on the battleground is called a
          permanent because it sticks around permanently (well, unless something
          destroys it). Some techs are Upgrades . An Upgrade enters the
          battleground attached to a permanent and affects that permanent while
          it’s on the battleground. If the upgraded permanent leaves the
          battleground, the Upgrade is put into its owner’s graveyard.
        </p>

        <h2>Artifact</h2>

        <p>
          Like a tech, an artifact is a permanent, so it’ll stay on the
          battleground affecting the game. Most artifacts are colorless, so you
          can select one no matter what kinds of planets you have. Some
          artifacts are Equipment . You can pay to attach an Equipment to a
          creature you control to make that creature more powerful. If an
          equipped creature leaves the battleground, the Equipment doesn’t—the
          creature drops it and it remains on the battleground.
        </p>

        <h2>Creature</h2>

        <p>
          Creatures fight for you. They’re permanents, but unlike any other kind
          of permanent, creatures can attack and block. Each creature has power
          and toughness. Its power (the first number) is how much damage it
          deals in combat. Its toughness (the second number) is how much damage
          must be dealt to it in a single turn to destroy it. Creatures attack
          and block during the combat phase. Unlike other types of permanents,
          creatures enter the battleground with “phasing instability”: a
          creature can’t attack, or use an ability that has a tap symbol in its
          cost, until it was on the battleground under your control when your
          turn began. You can block with a creature or activate its other
          abilities no matter how long it’s been on the battleground. Artifact
          (or Tech) creatures are both artifacts (or Tech) and creatures.
          They’re usually colorless like other artifacts, and they can attack
          and block like other creatures. An artifact creature can be affected
          by anything that affects artifacts, as well as anything that affects
          creatures. All types are always inclusive.
        </p>

        <h2>Fleet Admiral</h2>

        <p>
          Fleet Admirals are powerful allies you can call on to fight by your
          side. They’re permanents, and each one enters the battleground with
          the number of loyalty counters indicated in its lower right corner.
          Each fleet admiral has abilities that add or remove loyalty counters
          to activate. For example, the symbol +1 means “Put one loyalty counter
          on this fleet admiral,” and the symbol -2 means “Remove two loyalty
          counters from this fleet admiral.” You can activate one of these
          abilities only at the time you could select a shipment, and only if
          none of that fleet admiral's abilities have been activated yet that
          turn. Your fleet admiral can be attacked by your opponent’s creatures
          (if so, you can block as normal), and your opponent can damage them
          with their selections and abilities instead of damaging you. Any
          damage dealt to a fleet admiral causes it to lose that many loyalty
          counters. If it has no loyalty counters, it’s put into your graveyard.
          Only one version of a fleet admiral on the battleground under the
          control of a single player at the same time. If two or more fleet
          admiral with the same subtype are ever onto the battleground under the
          control of a single player at the same time, its controller chooses
          one and puts the rest into his/her graveyard once state-based actions
          are checked.​
        </p>

        <h2>Planets</h2>

        <p>
          Although planets are permanents, they aren’t selected. To play a
          planet, just put it onto the battleground. This happens immediately,
          so no player can do anything else in response. You can play a planet
          only during one of your main phases while the stack is empty. You
          can’t play more than one planet a turn. Most planets have abilities
          that make energy. You’ll use planets to make the energy you need to
          pay for selections and abilities. Each basic planet has an energy
          ability that makes one energy of a particular color. Deserts make
          white energy, Seas make blue energy, Caverns make black energy, Lavas
          make red energy, and Forests make green energy. Any planet other than
          these five is a nonbasic planet.
        </p>

        <h2>Game Zones</h2>

        <h2>Hand</h2>

        <p>
          When you draw cards, they go to your hand, just as in most other card
          games. No one except you can look at the cards in your hand. You start
          the game with seven cards in your hand, and you have a maximum hand
          size of seven. (You may have more than seven cards in your hand, but
          you must discard down to seven at the end of each of your turns.) Each
          player has his or her own hand.
        </p>

        <h2>Battleground</h2>

        <p>
          You start the game with nothing on the battleground, but this is where
          the action is going to be. On each of your turns, you can play a
          planet from your hand. Creatures, artifacts, techs, and fleet admirals
          also enter the battleground after they resolve. You can arrange your
          permanents however you want (we recommend putting planets closest to
          you), but your opponent must be able to see all of them and clearly
          tell whether they’re tapped . This zone is shared by both players.​
        </p>

        <h2>Graveyard</h2>

        <p>
          Your graveyard is your discard pile. Your hack and shipment selections
          go to your graveyard when they resolve . Your cards go to your
          graveyard if an effect causes them to be discarded , destroyed ,
          scraped , or countered . Your fleet admirals go to your graveyard if
          they lose all their loyalty counters. Your creatures go to your
          graveyard if the damage they’re dealt in a single turn is equal to or
          greater than their toughness, or if their toughness is reduced to 0 or
          less. Cards in your graveyard are always face up and anyone can look
          at them at any time. Each player has his or her own graveyard.
        </p>

        <h2>The Stack</h2>

        <p>
          Selections and abilities exist on the stack. They wait there to
          resolve until both players choose not to make any new selections or
          activate any new abilities. Then the last selection or ability that
          was put onto the stack resolves, and players get a chance to make
          selections and activate abilities again. (You’ll learn more about
          making selections and activating abilities the next section.) This
          temporary zone is shared by both players.​
        </p>

        <h2>Database</h2>

        <p>
          ​When the game begins, your deck of cards becomes your database (your
          draw pile). It’s kept face down, and the cards stay in the order they
          were in at the beginning of the game. No one can look at the cards in
          your database, but you can know how many cards are in each player’s
          database. Each player has his or her own database of at least 60
          cards.
        </p>

        <h2>Exile</h2>

        <p>
          If a selection or ability exiles a card, that card is put in a game
          area that’s set apart from the rest of the game. The card will remain
          there forever, unless whatever put it there is able to bring it back.
          Exiled cards are normally face up. This zone is shared by both
          players. ​
        </p>

        <h2>Producing Energy</h2>
        <p>
          To do just about anything else in the game, you first need to be able
          to produce energy. Think of energy as Galactic credits, or money—it’s
          what you use to pay most costs. Each energy is either one of the five
          colors or is colorless . When a cost requires colored energy, you’ll
          see colored energy symbols (W for white, U for blue, B for black, R
          for red, G for green). When any kind of energy can be used to pay the
          cost, you’ll see a symbol with a number in it (like 2 ). Where does
          energy come from? Nearly every planet in the game has an ability that
          produces energy. Basic planets have a different template to show
          this—you can tap one of them to add one energy of that color to your
          energy pool . (Your energy pool is where energy is stored until you
          spend it.) Other planets, as well as some creatures, artifacts, and
          selections, may also make energy. They’ll say something like “Add G to
          your energy pool.” Energy that you’ve made doesn’t last forever. At
          the end of each step or phase of the turn, any unused energy in your
          energy pool disappears.
        </p>

        <h2>Tapping</h2>

        <p>
          To tap a card is to turn it sideways. You do this when you use a
          planet to make energy, when you attack with a creature, or when you
          activate an ability that has the tap symbol as part of its cost (T
          means “tap this permanent”). When a permanent is tapped, that usually
          means it’s been used for the turn. You can’t tap it again until it’s
          been untapped (straightened out). Sometimes an effect will tap a
          permanent. If this happens, you don’t get the effect you’d get if you
          chose to tap your permanent yourself. At the beginning of each of your
          turns, you untap your tapped cards so you can use them again.
        </p>

        <h2>Selections</h2>

        <p>
          Now that you can make energy, you’ll want to use it to make
          selections. All cards except planets are selections. You can select
          shipments, creatures, artifacts, techs, and fleet admirals only during
          one of your main phases when there’s nothing else on the stack. Hacks
          can be selected at any time.
        </p>

        <h2>Making a selection​</h2>

        <p>
          To make a selection, take the card you want to select from your hand,
          show it to your opponent, and put it on the stack. (The stack is the
          game zone where selections live. It’s usually in the middle of the
          table.) There are a few choices that you need to make right now. If
          the selection is a hack or shipment and says “Choose one —,” you
          choose which of the options you’re using. If the selection is a hack
          or shipment and it has a target, you choose what (or who) that target
          is. Upgrade selections also target the permanents they’ll upgrade. If
          the selection has X in its cost, you choose what number X stands for.
          Other choices will be made later, when the selection resolves.Now
          check what the selection’s cost is. Tap your planets to produce the
          energy necessary to pay that cost, and pay it. Once you do that, the
          selection has been made.​
        </p>

        <h2>Target</h2>

        <p>
          When you see the word “target” on a selection or ability, you have to
          choose one or more things for the selection or ability to affect.
          You’ll be able to choose only certain kinds of things, such as “target
          red permanent” or “target creature or player.” You choose the targets
          for a selection when you cast it, and you choose targets for an
          activated ability when you activate it. If you can’t meet the
          targeting requirements, you can’t make the selection or activate the
          activated ability. Once you choose targets, you can’t change your mind
          later. When the selection or ability resolves, it checks the targets
          to make sure they’re still legal (they’re still there, and they match
          the requirements stated by the selection or ability). If a target
          isn’t legal, the selection or ability can’t affect it. If none of the
          targets are legal, the selection or ability is countered and does
          nothing at all.
        </p>

        <h2>Responding to a selection</h2>

        <p>
          The selection doesn’t resolve (have its effect) right away—it has to
          wait on the stack. Each player, including you, now gets a chance to
          select a hack or activate an activated ability in response . If a
          player does, that hack or ability goes on the stack on top of what was
          already waiting there. When all players decline to do anything, the
          top selection or ability on the stack will resolve.
        </p>

        <h2>Resolving a selection</h2>

        <p>
          When a selection resolves, one of two things happens. If the selection
          is a hack or a shipment, it has its effect (in other words, you follow
          the instructions on the card), then you put the card into your
          graveyard. If the selection is a creature, artifact, tech, or fleet
          admiral, you put the card on the table in front of you, near your
          planets. The card is now on the battleground. Any of your cards on the
          battleground is called a permanent because it sticks around
          permanently (well, until something happens to it). Many permanents
          have abilities, which is text on them that affects the game. After a
          selection or ability resolves, both players get the chance to play
          something new. If no one does, the next thing waiting on the stack
          will resolve (or if the stack is empty, the current part of the turn
          will end and the game will proceed to the next part). If either player
          plays something new, it goes on top of the stack and the process
          repeats.
        </p>

        <h2>Abilities</h2>

        <p>
          As you start to accumulate permanents on the battleground, the game
          will change. That’s because many permanents have text on them that
          affects the game. This text tells you a permanent’s abilities. There
          are three different kinds of abilities a permanent can have: static
          abilities, triggered abilities, and activated abilities.​
        </p>

        <h2>Static abilities</h2>

        <p>
          A static ability is text that is always true while that card is on the
          battleground. For example, Maintenance Staff is a tech with the
          ability “White creatures you control have +1/+1.” You don’t activate a
          static ability. It just does what it says.
        </p>

        <h2>Triggered abilities</h2>

        <p>
          A triggered ability is text that happens when a specific event occurs
          in the game. For example, Forest Sage is a creature with the ability
          “When Forest Sage enters the battleground, draw a card.” Each
          triggered ability starts with the word “when,” “whenever,” or “at.”
          You don’t activate a triggered ability. It automatically triggers
          whenever the first part of the ability happens. The ability goes on
          the stack just like a selection, and resolves just like a selection.
          If the ability triggers but then the permanent the ability came from
          leaves the battleground, the ability will still resolve. You can’t
          choose to delay or ignore a triggered ability. However, if the ability
          targets something or someone but you can’t choose a legal target for
          it, the ability won’t do anything.
        </p>

        <h2>Activated abilities</h2>

        <p>
          ​An activated ability is an ability that you can activate whenever you
          want, as long as you can pay the cost. For example, Cruiser is a
          creature with the ability “ 1B:Cruiser deals 2 damage to target
          creature. Activate this ability only once per turn.” Each activated
          ability has a cost, then a colon (“:”), then an effect. Activating one
          works exactly like selecting a hack, except there’s no card to put on
          the stack. The ability goes on the stack just like a selection, and
          resolves just like a selection. If you activate an ability but then
          the permanent the ability came from leaves the battleground, the
          ability will still resolve. Some activated abilities contain the tap
          symbol in their costs. This means that you must tap the permanent to
          activate the ability. You can’t activate the ability if the permanent
          is already tapped.
        </p>

        <h2>Keywords</h2>

        <p>
          Some permanents have abilities that are shortened to a single word or
          phrase. Most of these have reminder text that gives you a brief
          description of the ability’s effect. Keyword abilities in the alpha
          set include deathtouch, defender, upgrade, equip, first strike, flash,
          flying, haste, lifelink, protection, reach, stealth, trample,
          datamine, and vigilance. Most of these are static abilities, but
          keyword abilities can also be triggered abilities or activated
          abilities. Detailed explanations of each of these abilities can be
          found in the glossary section.​
        </p>

        <h2>Attacking and blocking</h2>

        <p>
          The primary way to win the game is to attack with your creatures. If a
          creature that’s attacking your opponent isn’t blocked, it deals damage
          equal to its power to him or her. It doesn’t take that many hits to
          drop your opponent all the way from 20 life to 0!​The middle of each
          turn is the combat phase . (You’ll learn about the parts of the turn
          in a bit.) In your combat phase, you choose which of your creatures
          will attack, and you choose who or what they will attack. Each one can
          attack your opponent or one of your opponent’s fleet admirals, but not
          any of his or her creatures. You tap the attacking creatures. They all
          attack at the same time, even if they’re attacking different things.
          You can attack with a creature only if it’s untapped, and only if it
          was on the battleground under your control when the turn began. Your
          opponent chooses which of his or her creatures will block. Tapped
          creatures can’t be declared as blockers. For blocking, it doesn’t
          matter how long the creature has been on the battleground. Each
          creature can block only one attacker, but multiple blockers can gang
          up on a single attacking creature. If that happens, the attacking
          player orders the blockers to show which is first in line for damage,
          which is second, and so on. Creatures don’t have to block. After all
          blockers are chosen, combat damage is assigned. Each creature—both
          attackers and blockers—deals damage equal to its power.
        </p>
        <ul>
          <li>
            An attacking creature that isn’t blocked deals damage to the player
            or fleet admiral it’s attacking.
          </li>
          <li>
            An attacking creature that is blocked deals damage to the blocking
            creatures. If one of your attacking creatures is blocked by multiple
            creatures, you decide how to divide its combat damage among them.
            You must assign at least enough damage to the first blocking
            creature in line to destroy it before you can assign damage to the
            next one in line, and so on.
          </li>
          <li>
            A blocking creature deals damage to the attacker it’s blocking. If
            damage is dealt to your opponent, he or she loses that much life!If
            damage is dealt to your opponent’s fleet admiral, that many loyalty
            counters are removed from it. If a creature is dealt damage equal to
            or greater than its toughness over the course of a single turn, that
            creature is destroyed and goes to its owner’s graveyard. If a
            creature takes damage that isn’t lethal, that creature stays on the
            battleground, but the damage doesn’t wear off until the turn ends.​
          </li>
        </ul>

        <h2>Building your own deck</h2>

        <p>
          You play a Galaxy Conquest game with your own customized deck. You
          build it yourself using whichever Galaxy Conquest cards you want.There
          are two rules: Your deck must have at least 60 cards, and your deck
          can’t have more than four copies of any single card (except for basic
          planets).The rest is up to you, but here are some guidelines for
          getting started:Planets: A good rule of thumb is that 2/5 of your deck
          should be planets. A 60-card deck usually has about 24
          planets.Creatures: Creatures account for 20 to 30 cards in a typical
          60-card deck. Choose creatures that have a variety of energy costs.
          Low-cost creatures are potent early on, but high-cost creatures can
          quickly win a game once they enter the battleground.Other cards:
          Artifacts, techs, fleet admirals, hacks, and shipments round out your
          deck.After you play with your new deck for a while, you can start to
          customize it. Take out cards you don’t feel are working well and add
          new cards you want to try. The best part about deck building games is
          being able to play with whatever cards you want, so start
          experimenting!​Feels a little overwhelming to build your own deck from
          scratch? Check out our intro decks! They are specifically made to be
          simple, effective and fun.
        </p>

        <h2>The golden rule</h2>

        <p>
          When a Galaxy Conquest card contradicts the rulebook, the card wins.
          For example, the rules say you can play only one planet per turn. But
          Rapid Conquest reads “You may play an additional planet this turn.”
          Rapid Conquest changes the rules until the end of your turn. One of
          the things that makes the Galaxy Conquest game fun to play is that
          there are individual cards that let you break almost every rule.​
        </p>

        <h2>Playing a game</h2>

        <p>
          Now that you know the elements of the game and how to perform the main
          actions, it’s time to walk through a turn. This section describes what
          happens in each part of a turn. In a typical game, you’ll skip many of
          these parts (for example, nothing usually happens in the “beginning of
          combat” step). An actual Galaxy Conquest game is pretty casual,
          despite how complex the structure may seem. Most matches are best of
          two, so don't despair if you lose the first game.​
        </p>

        <h2>Get a deck</h2>

        <p>
          ​You’ll need your own Galaxy Conquest deck. You’ll also need a way to
          keep track of both players’ life totals.
        </p>

        <h2>Get a friend</h2>

        <p>
          You need an opponent! Your opponent will play against you using his or
          her own deck.​
        </p>

        <h2>Start the game</h2>

        <p>
          Each player starts at 20 life. You win the game by reducing your
          opponent to 0 life. You also win if your opponent has to draw a card
          when none are left in his or her deck, or if a selection or ability
          says that you win. Decide which player will go first. If you’ve just
          played the same opponent, the loser of the last game decides who goes
          first. Otherwise, roll a die or flip a coin to see who gets to decide.
          Each player shuffles his or her deck, then draws a hand of seven cards
          to start. If you don’t like your opening hand, you can mulligan .
          Shuffle your hand back into your deck and draw a new hand of six
          cards. You can keep doing this, drawing a hand of one fewer card each
          time, until you decide to keep your cards. When you do, you must
          datamine 1.
        </p>

        <h2>Parts of a turn</h2>

        <p>
          Below are the parts of a turn. Each turn proceeds in the same
          sequence. Whenever you enter a new step or phase, any triggered
          abilities that happens during that step or phase trigger and are put
          on the stack. The active player (the player whose turn it is) gets to
          make selections and activate abilities, then the other player does.
          When both players in a row decline to do anything and nothing is
          waiting to resolve, the game will move to the next step. With each
          part of the turn is a description of what can happen during that part,
          if it’s your turn.
        </p>

        <h2>Beginning Phase </h2>
        <ul>
          <li>
            Untap step: You untap all your tapped permanents. On the first turn
            of the game, you don’t have any permanents, so you just skip this
            step. No one can make selecitons or activate abilities during this
            step.
          </li>
          <li>
            Upkeep step: This part of the turn is mentioned on a number of
            cards. If something is supposed to happen just once per turn, right
            at the beginning, an ability will trigger “at the beginning of your
            upkeep.” Players can select hacks and activate abilities.
          </li>
          <li>
            Draw step: You draw a card from your database. (The player who goes
            first skips the draw step on his or her first turn to make up for
            the advantage of going first.) Players can then select hacks and
            activate abilities.
          </li>
        </ul>

        <h2>First Main Phase</h2>
        <ul>
          <li>
            You can select any number of shipment, hacks, creatures, artifacts,
            techs, and fleet admirals, and you can activate abilities. You can
            play a planet during this phase, but remember that you can play only
            one planet during your turn. Your opponent can select hacks and
            activate abilities.
          </li>
        </ul>

        <h2>Combat Phase</h2>
        <ul>
          <li>
            Beginning of combat step: Players can select hacks and activate
            abilities.
          </li>

          <li>
            Declare attackers step: You decide which, if any, of your untapped
            creatures will attack, and which player or fleet admiral they will
            attack, then they do so. This taps the attacking creatures. Players
            can then select hacks and activate abilities.
          </li>
          <li>
            Declare blockers step: Your opponent decides which, if any, of his
            or her untapped creatures will block your attacking creatures, then
            they do so. If multiple creatures block a single attacker, you order
            the blockers to show which is first in line for damage, which is
            second, and so on. Players can then select hacks and activate
            abilities.
          </li>
          <li>
            Combat damage step Each attacking or blocking creature that’s still
            on the battleground assigns its combat damage to the defending
            player (if it’s attacking that player and wasn’t blocked), to a
            fleet admiral (if it’s attacking that fleet admiral and wasn’t
            blocked), to the creature or creatures blocking it, or to the
            creature it’s blocking. If an attacking creature is blocked by
            multiple creatures, you divide its combat damage among them by
            assigning at least enough damage to the first blocking creature in
            line to destroy it before assigning damage to the next one in line,
            and so on. Once players decide how the creatures they control will
            deal their combat damage, the damage is all dealt at the same time.
            Players can then select hacks and activate abilities.
          </li>
          <li>
            End of combat step: Players can select hacks and activate abilities.
          </li>
        </ul>

        <h2>Second Main Phase</h2>
        <ul>
          <li>
            Your second main phase is just like your first main phase. You can
            make every type of selection and activate abilities, but your
            opponent can only select hacks and activate abilities. You can play
            a planet during this phase if you didn’t during your first main
            phase.
          </li>
        </ul>

        <h2>Ending Phase </h2>
        <ul>
          <li>
            End step: Abilities that trigger “at the beginning of your end step”
            go on the stack. Players can <span>select hacks</span> and activate
            abilities.
          </li>
          <li>
            Cleanup step: If you have more than seven cards in your hand, choose
            and discard cards until you have only seven. Next, all damage on
            creatures is removed and all “until end of turn” effects end. No one
            can select hacks >or activate abilities unless an ability triggers
            during this step.
          </li>
        </ul>

        <h2>The next turn</h2>

        <p>
          Now it’s your opponent’s turn. That player untaps his or her
          permanents and goes from there. After that player is done, it will be
          your turn again. Keep going until a player is reduced to 0 life. As
          soon as a player has 0 life, the game ends immediately and the other
          player wins!​
        </p>

        <h2>Sideboard</h2>

        <p>
          Each deck may have a sideboard—a group of extra cards that are
          particularly good against certain opponents. For example, a card that
          says “Destroy all green creatures” is great against someone playing a
          green deck, but useless against anyone else. After you play a game
          against an opponent, you may move cards from your sideboard into your
          main deck, then play that opponent again. You must reset your deck to
          its original configuration before playing someone new. Your sideboard
          consists of a maximum of 15 cards. You build your sideboard as you
          build your deck. Your combined deck and sideboard can’t have more than
          four copies of any card other than basic planet cards. Between games,
          you may swap in cards from your sideboard in exchange for cards from
          your main deck, as long as your deck has at least 60 cards at the
          beginning of the game.
        </p>
      </div>
    );
  }
}

export default HowToPlay;
