import React from "react";
import "./App.scss";
import { Switch, Route, withRouter } from "react-router";
import Home from "./containers/home";
import Glossary from "./containers/glossary";
import HowToPlay from "./containers/how-to-play";
import Tips from "./containers/tips";
import Navigation from "./components/navigation";

class App extends React.Component {
  render() {
    return (
      <div className="App bp3-running-text">
        <Navigation />
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route exact={true} path="/glossary" component={Glossary} />
          <Route exact={true} path="/how-to-play" component={HowToPlay} />
          <Route exact={true} path="/tips" component={Tips} />
        </Switch>
      </div>
    );
  }
}

export default App;
